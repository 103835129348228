import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-page"
import FeaturesComponent from "../../components/pages/main/landing-features"
import AboutComponent from "../../components/pages/main/about"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import Layout from "../../components/layout"
import Head from "../../components/pages/features/head"
import FeaturesPricingCards from "../../components/pages/features/cards"
import FeatureAccordion from "../../components/pages/main/accordion"
import Discount from "../../components/pages/main/discount"
import Breadcrumb from '../../components/controls/breadcrumbs';

const telephonyAccordionData = [
  {
    name: "iptelephony",
    title: "Что такое IP-телефония или Виртуальная АТС?",
    content: `
      <p>Это телефонная связь, которая работает через Интернет без необходимости приобретать дорогостоящее оборудование, оснащать офис проводами или привязываться к определенному местоположению. Идеально подходит для удаленной онлайн работы.</p>
    `,
  },
  {
    name: "integration",
    title: "Как настроить интеграцию IP-телефонии и СРМ для образования?",
    content: `
      <p>Телефония подключается к CRM-системе в “Настройках”. Информация о звонках автоматически появляется в системе. Перед тем, как приступить к настройкам Телефонии в Параплан, вам необходимо зарегистрироваться на сайте выбранного провайдера IP-телефонии, а также настроить всё для работы в личном кабинете сервиса.</p>
    `,
  },
  {
    name: "iptelephonyCRM",
    title: "Как работает интеграция телефонии с CRM для детских и учебных центров?",
    content: `
      <p>Интеграция IP-телефонии и CRM-системы работает по открытому API. Благодаря внутренним настройкам, сервис Виртуальной АТС самостоятельно передает информацию в СРМ. Далее Параплан обрабатывает и хранит данные внутри системы. Например, CRM-система уведомляет о входящих звонках звуком и “вибрацией” иконки на экране. Или автоматически добавляет неопределенный номер в графу “Неразобранное” воронки продаж . Кроме этого, звонок появится в “Истории звонков”, откуда его можно перевести в лид. Вдобавок, CRM для образования Параплан умеет определять звонящего. Если звонит действующий клиент, это подсветится, и можно открыть  его карточку во время разговора. Звонки учеников сразу попадают в их карточки в поле “Звонки”, что позволяет сразу работать с ними (поставить задачу и т.д.)</p>
    `,
  },
  {
    name: "advantages",
    title: " Какие преимущества в интеграция телефонии с CRM для детских и учебных центров?",
    content: `
      <p>Интеграция IP-телефонии с CRM системой для детского центра позволяет:</p>
      <ol>
        <li><strong>Отслеживать историю звонков.</strong>Входящие и исходящие звонки появляются в разделе “Связь” в “Истории звонков”. Там же переводите звонок в лид (заявку), создавайте ученика или задачу. Такой подход исключает фиксирование важной информации на стикерах или в заметках, помогает регламентировать работу с лидами.</li>
        <li><strong>Назначать ответственных за пропущенные звонки.</strong>Выберите одного менеджера, который будет обязан перезванивать клиентам по пропущенным звонкам. Данному менеджеру будет автоматически создаваться задача о пропущенном и приходить уведомление по Email (при включенных автоуведомлениях). Последующая обработка данных звонков обязательна.</li>
        <li><strong>Ставить задачи по звонкам.</strong>Например, перезвонить завтра или предложить дополнительные услуги через месяц. Ставить задачи можно сразу в системе во время разговора. Тогда ни одно обещание клиенту не будет упущено. Кроме этого, вы всегда можете прослушать запись звонка и вспомнить, какие документы ждет от вас клиент.</li>
        <li><strong>Формировать звонки в лиды.</strong>Пользователь CRM-системы может сам указать источник лидов: наружная реклама, листовки, рекомендации и т.д.</li>
        <li><strong>Контролировать отдел продаж.</strong>Проверяйте, кому и когда звонили, сколько длился разговор, выполнены ли задачи. Вы также сможете прослушать записи входящих и исходящих разговоров сразу в системе, если у вас в сервисе IP-телефонии подключена функция записи разговоров.</li>
        <li><strong>Вести учет звонков.</strong>Отслеживайте динамику звонков, фиксируйте их источники. Ни один звонок не потеряется.</li>
      </ol>
      <p>Благодаря интеграции телефонии с CRM-системой объединяйте звонки и данные клиентов в единой системе, формируйте задачи по звонкам, храните и прослушивайте записи разговоров для более качественного клиентского сервиса.</p>
    `,
  }
]

const IptelephonyFeaturePage = (props) => {
    const crumbs = [
        { name: 'Главная', url: '/'},
        { name: 'Возможности', url: '/feature'},
        { name: 'IP-телефония', url: ''}
    ];
    return (
      <Layout page={"iptelephony"} headerColor="#319DED">
          <SEO
            title="Подключите IP-телефонию в CRM-cистеме Параплан"
            description="Выполните интеграцию IP-телефонии с CRM Параплан и получайте уведомления о вызовах, создавайте лиды из звонков и ставьте по ним задачи."
            url={"https://paraplancrm.ru" + props.location.pathname}
          />
          <HeroFeaturePage
            name={"iptelephony"}
            htmlTitle='Ведите учет звонков <br/>в CRM-системе'
            description='Получайте уведомления о входящих и исходящих вызовах через IP-телефонию, создавайте лиды из звонков или ставьте по ним задачи.'
            color='#319DED'
            btnDark={true}
            image={require("../../images/cover/iptelephony.png")} />
          <Breadcrumb crumbs={ crumbs } arrowcolor={"blue"}/>
          <Head
            center={true}
            title="Интеграция с IP-телефонией"
            subtitle="Получайте уведомления о входящих и исходящих вызовах, создавайте лиды из звонков <br/> или ставьте по ним задачи."
          />
          <AboutComponent
            title='История звонков'
            description='Отслеживайте историю звонков, оценивайте их динамику в разделе “Связь”. Всегда под рукой история коммуникации с каждым лидом или клиентом в его профиле.'
            image={require("../../images/screenshot/iptelephony/automatization.png")}
            imageWidth={676}
            imageHeight={364}
            bgSize={"position-bottom"}
            reverse={true}
            color='#03C4A4' />
          <AboutComponent
            title='Автоматические действия'
            description='Получайте уведомления о входящих звонках на главном экране. Система определит клиента, если его номер есть в системе. Если нет - автоматически создаст лида в “Воронке продаж”.'
            image={require("../../images/screenshot/iptelephony/history.png")}
            imageWidth={676}
            imageHeight={364}
            bgSize={"position-bottom"}
            color='#319DED' />
          <Discount />
          <AboutComponent
            title='Контроль сотрудников'
            description='Назначайте ответственных за звонки. Проверяйте, кому и когда звонили, сколько длился разговор, выполнены ли задачи. Прослушивайте записи входящих и исходящих вызовов сразу в системе.'
            image={require("../../images/screenshot/iptelephony/control.png")}
            imageWidth={676}
            imageHeight={364}
            bgSize={"position-bottom"}
            reverse={true}
            color='#FFC800' />
          <AboutComponent
            title='Разные сервисы'
            description='В Параплан реализована интеграция IP-телефонии с тремя сервисами: <a href="https://www.mango-office.ru/products/virtualnaya_ats/?p=400003141" target="_blank">Mango OFFICE</a>, <a href="https://ordering.megafon.ru/partners/?utm_source=holmont&utm_medium=site&utm_campaign=vats_crm_3mes" target="_blank">Мегафон</a>, <a href="https://novofon.com?ref=9d8844a9e225891be06e522552351fd6" target="_blank">Zadarma</a>. Выберите того провайдера, который подходит именно вам, и подключите услугу Виртуальной АТС. Работа в CRM-системе станет еще удобнее и эффективнее.'
            image={require("../../images/screenshot/iptelephony/services.png")}
            imageWidth={676}
            imageHeight={364}
            bgSize={"position-bottom"}
            color='#6d20d2' />
          <FeaturesPricingCards
            withMargins={true}
            title='Консультируйте клиентов еще быстрее и лучше!'
            subtitle='Стоимость интеграции с IP-телефонией(отдельно оплачиваются тарифы выбранного провайдера IP-телефонии)'
          />
          <FeaturesComponent title={'Дополнительные возможности'} current={"iptelephony"} />
          <ContactFormComponent />
          <FeatureAccordion
            data={telephonyAccordionData}
            title='Преимущества интеграции телефонии с CRM для детских центров'
            descr='Не смотря на популярность переписок в мессенджерах и социальных сетях, телефонные разговоры остаются основным способом общения с клиентами. Однако детские и учебные центры могут столкнуться с ситуацией, когда полученная по телефону информация теряется, куда-то записывается и забывается. Это ведет к негативу со стороны клиентов, так как обещанные документы не отправляются, менеджер не перезванивает в назначенное время, а запись на занятие не осуществляется. Для снижения ошибок в работе из-за человеческого фактора, рекомендуем настроить интеграцию телефонии с CRM. Рассказываем подробнее, какие плюсы для детского или учебного центра кроятся в такой функциональности, как CRM телефония.'
          />
      </Layout>
    )
}

export default IptelephonyFeaturePage
